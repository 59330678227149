/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2022-12-25",
    versionChannel: "nightly",
    buildDate: "2022-12-25T00:15:38.799Z",
    commitHash: "6dc628e49f7a1de9115a68eb99f0b8ce2e1f14a8",
};
